import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import { ErrorWrapper, ErrorContent } from './error.style';
import LinkButton from '../addcraft/LinkButton';
import Illustration from './error.illustration';

const ErrorSec = ({ imageWrapper, title, homeButton}) => {

  const [armLeft, setArmLeft] = useState(0);
  const [armRight, setArmRight] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setArmLeft((armLeft) => armLeft + 12);
      setArmRight((armRight) => armRight - 10);
    }, 30);
    return () => clearInterval(interval);
  });

  return (
    <ErrorWrapper>
      <ErrorContent>
        <Heading {...title} content="404 - page not found :/" />
        <Box {...imageWrapper} className="image-wrapper">
            <Illustration rotationLeft={armLeft} rotationRight={armRight} />
        </Box>
        <LinkButton styling={homeButton}
          title="Return to homepage"
          href="/"
        />
      </ErrorContent>
    </ErrorWrapper>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object,
};

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ['40px', '55px'],
    maxWidth: '550px'
  },
  title: {
    fontSize: '48px',
    color: '#FFF',
    lineHeight: '1.31',
    textAlign: 'center',
    fontFamily: 'Montserrat Alternates',
    fontWeight: '600',
    mb: '80px'
  },
  homeButton: {
    background: "#FFF",
    color: "#000"
  },
};

export default ErrorSec;
