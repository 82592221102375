import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ResetCSS } from 'common/src/assets/css/style';
import { addcraftTheme } from 'common/src/theme/addcraft';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/addcraft/addcraft.style';

import "fontsource-montserrat-alternates/400.css";
import "fontsource-montserrat-alternates/500.css";
import "fontsource-montserrat-alternates/600.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";

const NotFoundPage = () => (
  <ThemeProvider theme={addcraftTheme}>
    <Fragment>
      <SEO
        title="addcraft - the Atlassian app makers"
        description="addcrafts mission is to create the missing pieces for the tools you use daily."
      />

      <ResetCSS />
      <GlobalStyle />

      <ContentWrapper>
        <ErrorSec />
      </ContentWrapper>
    </Fragment>
  </ThemeProvider>
);

export default NotFoundPage;
