import React from 'react';

const ErrorIllustration = (
  props
) => {

  var rls = "rotate("+props.rotationLeft+",300,300)";
  var rrs = "rotate("+props.rotationRight+",305,300)";

  return (
<svg width="100%" height="100%" viewBox="0 0 893 893" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.1" d="M756.018 718.271C879.181 595.062 879.181 395.3 756.018 272.09C632.856 148.881 433.169 148.881 310.006 272.09C186.843 395.3 186.843 595.062 310.006 718.271C433.169 841.48 632.856 841.48 756.018 718.271Z" fill="#F1CB6F"/>
<path d="M852.09 348.816L817.826 378.859L792.332 336.623L753.667 366.53L745.609 412.255L694.756 434.27V443.042V531.002H785.29L828.39 479.114L864.414 406.124L852.09 348.816Z" fill="#D8A045"/>
<path d="M602.563 392.541C602.563 392.541 594.099 433.829 652.299 516.403C652.299 516.403 671.361 539.706 668.178 578.893C668.178 578.893 670.887 616.15 691.878 617.471C712.87 618.792 793.889 617.471 793.889 617.471C801.14 616.088 807.685 612.228 812.404 606.549C817.123 600.871 819.722 593.729 819.756 586.344C820.907 559.079 845.284 500.484 856.288 487.14C867.292 473.795 903.282 412.287 882.967 349.053C882.967 349.053 856.288 292.185 843.524 289.272L856.288 378.655L846.436 396.03H832.52L800.051 444.193L748.419 449.409L722.891 493.44L708.4 480.705L704.338 447.614L656.227 447.106L633.035 405.92L602.563 392.541Z" fill="#F1CB6F"/>
<g opacity="0.5">
<path opacity="0.5" d="M722.925 490.087L731.593 513.762L727.259 539.91" stroke="white" stroke-miterlimit="10"/>
<path opacity="0.5" d="M731.592 510.883L764.061 525.346L747.844 591.121" stroke="white" stroke-miterlimit="10"/>
<path opacity="0.5" d="M800.085 444.194L810.852 479.554L805.469 510.883" stroke="white" stroke-miterlimit="10"/>
<path opacity="0.5" d="M810.852 479.554L832.96 453.677" stroke="white" stroke-miterlimit="10"/>
</g>
<path transform={rrs} fill-rule="evenodd" clip-rule="evenodd" d="M398.74 423.664L444.515 462.472C457.355 466.918 467.982 472.076 476.395 477.946C478.337 479.981 480.576 483.984 472.02 482.721C463.464 481.458 454.408 480.514 453.016 483.365C451.624 486.216 456.584 490.089 453.908 493.88C452.124 496.407 445.03 489.261 432.626 472.442L386.229 449.286L398.74 423.664Z" fill="#DAB596"/>
<path transform={rrs} fill-rule="evenodd" clip-rule="evenodd" d="M303.716 296.521L320.358 291.017C342.934 347.412 376.603 401.978 432.856 448.067L418.945 472.066C369.507 442.944 322.707 420.321 303.716 376.621C297.342 361.954 303.196 314.058 303.716 296.521Z" fill="#C5CFD6"/>
<path d="M791.892 641.824H699.361C696.667 641.824 694.083 640.754 692.178 638.848C690.274 636.942 689.203 634.358 689.203 631.663C689.203 628.968 690.274 626.384 692.178 624.478C694.083 622.573 696.667 621.502 699.361 621.502H791.892C794.586 621.502 797.169 622.573 799.074 624.478C800.979 626.384 802.049 628.968 802.049 631.663C802.049 632.998 801.786 634.319 801.276 635.552C800.765 636.784 800.017 637.905 799.074 638.848C798.131 639.792 797.011 640.54 795.779 641.051C794.547 641.561 793.226 641.824 791.892 641.824Z" fill="#666666"/>
<path d="M791.892 677.658H699.361C696.667 677.658 694.083 676.588 692.178 674.682C690.274 672.777 689.203 670.192 689.203 667.497C689.203 664.803 690.274 662.218 692.178 660.313C694.083 658.407 696.667 657.337 699.361 657.337H791.892C794.586 657.337 797.169 658.407 799.074 660.313C800.979 662.218 802.049 664.803 802.049 667.497C802.049 668.832 801.786 670.153 801.276 671.386C800.765 672.619 800.017 673.739 799.074 674.682C798.131 675.626 797.011 676.374 795.779 676.885C794.547 677.396 793.226 677.658 791.892 677.658Z" fill="#666666"/>
<path d="M698.717 638.979H792.501C793.76 638.979 795.006 639.227 796.168 639.709C797.33 640.19 798.387 640.896 799.276 641.786C800.166 642.676 800.872 643.733 801.353 644.896C801.835 646.059 802.083 647.305 802.083 648.564V648.564C802.074 651.103 801.062 653.536 799.267 655.332C797.472 657.128 795.04 658.14 792.501 658.149H698.717C696.182 658.132 693.755 657.116 691.962 655.322C690.169 653.529 689.153 651.101 689.136 648.564V648.564C689.145 646.025 690.157 643.592 691.952 641.796C693.747 640.001 696.179 638.988 698.717 638.979V638.979Z" fill="#7F7F7F"/>
<path d="M706.064 682.095H787.084C787.084 682.095 751.263 732.663 706.064 682.095Z" fill="#7F7F7F"/>
<path d="M677.59 352.779C677.59 352.779 676.27 352.068 674.205 350.781C673.121 350.137 671.733 349.392 670.243 348.478C668.754 347.563 666.858 346.581 665.233 345.429C661.508 343.262 657.344 340.586 652.841 337.876L649.455 335.776C648.304 335.099 647.119 334.422 646.07 333.676C643.733 332.22 641.296 330.933 638.892 329.51C636.488 328.088 634.016 326.902 631.68 325.514L624.333 321.788L617.562 318.706C615.361 317.859 613.296 316.945 611.332 316.132C609.369 315.319 607.642 315.014 605.915 314.404C604.188 313.795 602.8 313.592 601.581 313.185C599.144 312.542 597.688 312.067 597.688 312.067C597.688 312.067 599.11 311.661 601.683 311.221C603.217 310.866 604.782 310.662 606.355 310.611C608.184 310.611 610.249 310.611 612.517 310.611C614.786 310.611 617.19 311.153 619.763 311.559C622.336 311.966 624.875 312.846 627.617 313.625C630.36 314.404 632.967 315.59 635.675 316.741C638.384 317.893 640.991 319.316 643.564 320.806C646.137 322.296 648.677 323.786 651.013 325.514C653.356 327.102 655.616 328.809 657.784 330.628C661.695 333.792 665.351 337.257 668.72 340.992C671.16 343.616 673.422 346.399 675.491 349.324C676.249 350.44 676.949 351.593 677.59 352.779Z" fill="#EEBD4F"/>
<path d="M762.91 335.335C763.177 333.414 763.573 331.513 764.095 329.645C764.535 327.816 765.213 325.682 766.025 323.244C766.953 320.501 768.027 317.811 769.242 315.183C769.885 313.726 770.562 312.202 771.307 310.644C772.052 309.086 772.966 307.562 773.846 305.97C775.539 302.583 777.74 299.535 779.907 296.148C781.058 294.522 782.344 292.93 783.597 291.271C784.842 289.587 786.176 287.97 787.592 286.427C790.403 283.197 793.411 280.143 796.598 277.282L801.44 273.049L806.654 269.255C810.001 266.8 813.546 264.625 817.251 262.752C820.595 260.826 824.095 259.183 827.713 257.841C829.439 257.198 831.098 256.452 832.723 255.911L837.599 254.691C840.383 253.904 843.209 253.27 846.063 252.795C848.636 252.49 850.905 252.32 852.835 252.151C856.525 251.846 858.624 251.812 858.624 251.812C858.624 251.812 856.66 252.727 853.309 254.115C851.616 254.793 849.584 255.64 847.214 256.486C844.844 257.333 842.44 258.857 839.664 260.077L835.398 262.109L830.997 264.615C827.814 266.329 824.729 268.218 821.754 270.271L817.048 273.455C815.389 274.437 814.001 275.826 812.409 276.842L807.737 280.466L803.302 284.531C800.221 287.071 797.546 290.051 794.736 292.795C791.926 295.538 789.488 298.688 787.084 301.465C784.68 304.243 782.243 307.122 780.313 309.967C778.383 312.812 776.182 315.386 774.456 317.926C772.729 320.466 771.07 322.803 769.682 324.7C768.294 326.597 767.075 328.663 766.296 330.221C763.96 333.405 762.91 335.335 762.91 335.335Z" fill="#F7EC41"/>
<path d="M708.874 323.447L691.506 305.225L683.109 296.554L673.629 287.037C670.447 283.65 666.858 280.5 663.472 276.876C660.086 273.252 656.328 270.102 652.638 266.715C648.947 263.328 645.359 259.941 641.6 256.961C637.842 253.98 634.355 250.593 630.631 247.85C626.907 245.106 623.589 242.058 620.067 239.687L615.057 236.029C613.546 234.822 611.975 233.691 610.35 232.642C607.236 230.644 604.425 228.679 601.886 226.952C599.347 225.225 597.045 224.005 595.115 222.854C591.357 220.551 589.224 219.128 589.224 219.128C589.224 219.128 591.695 219.738 595.995 221.025C598.128 221.702 600.769 222.38 603.647 223.531C606.525 224.683 609.741 226.071 613.228 227.663C616.954 229.494 620.581 231.518 624.096 233.726C628.129 235.954 632.039 238.396 635.811 241.042C639.84 243.65 643.733 246.664 647.695 249.746C651.656 252.829 655.414 256.283 659.308 259.602C663.201 262.922 666.655 266.681 670.21 270.204C673.765 273.726 676.981 277.452 680.062 281.076C683.143 284.7 686.089 288.256 688.729 291.745C691.37 295.234 693.774 298.519 695.941 301.669C699.5 306.69 702.8 311.89 705.827 317.249C707.825 321.11 708.874 323.447 708.874 323.447Z" fill="#F7EC41"/>
<path d="M728.545 285.106C728.545 285.106 728.545 283.176 728.545 279.823C728.795 275.183 729.281 270.56 730.001 265.97C730.475 263.125 730.915 260.043 731.66 256.825C732.405 253.608 733.048 250.051 733.996 246.664C734.944 243.277 735.96 239.619 737.111 235.995C738.262 232.371 739.65 228.815 740.937 225.191C742.224 221.567 743.917 218.078 745.474 214.623C747.031 211.169 748.86 207.849 750.553 204.699C753.799 198.925 757.453 193.39 761.488 188.137C763.317 185.868 765.111 183.87 766.668 182.108C768.027 180.59 769.487 179.164 771.036 177.841C773.508 175.538 775.031 174.454 775.031 174.454C775.031 174.454 774.185 176.249 772.593 179.196C771.849 180.686 770.765 182.345 769.783 184.445C768.801 186.545 767.684 188.848 766.398 191.219C765.111 193.59 763.994 196.571 762.572 199.416C761.15 202.261 760.032 205.512 758.543 208.628C757.053 211.744 755.8 215.165 754.412 218.552L750.282 228.713C744.932 242.701 739.583 256.724 735.554 267.223C733.556 272.541 731.762 276.944 730.509 280.026C729.256 283.108 728.545 285.106 728.545 285.106Z" fill="#EEBD4F"/>
<path d="M581.572 238.434C579.897 234.728 578.538 230.887 577.509 226.952C576.451 223.138 575.592 219.272 574.936 215.368C574.271 211.455 573.808 207.509 573.548 203.548C573.193 199.536 573.193 195.502 573.548 191.49C575.231 195.146 576.59 198.943 577.611 202.837C579.714 210.485 581.041 218.326 581.572 226.241C581.935 230.297 581.935 234.377 581.572 238.434V238.434Z" fill="#F7EC41"/>
<path d="M556.247 225.733C559.277 223.044 562.54 220.629 565.998 218.518C572.727 214.238 579.874 210.652 587.328 207.815C591.092 206.347 594.978 205.214 598.941 204.428C595.916 207.109 592.665 209.523 589.224 211.643C585.857 213.785 582.376 215.741 578.796 217.502C575.238 219.298 571.588 220.903 567.86 222.312C564.09 223.771 560.206 224.915 556.247 225.733V225.733Z" fill="#F7EC41"/>
<path d="M595.724 327.986C594.263 326.509 592.916 324.924 591.695 323.244C590.544 321.787 589.325 319.857 588.073 318.129C586.82 316.402 585.601 314.065 584.382 311.965C582.211 308.045 580.333 303.969 578.762 299.772C578.005 297.833 577.394 295.841 576.934 293.811C578.384 295.297 579.731 296.881 580.963 298.553C583.671 302.122 586.105 305.893 588.242 309.831C589.393 311.999 590.544 314.167 591.628 316.233C592.711 318.299 593.422 320.297 594.065 322.058C594.742 323.997 595.297 325.977 595.724 327.986V327.986Z" fill="#E9A727"/>
<path d="M572.634 324.971C573.633 323.13 574.764 321.364 576.019 319.687C577.103 318.163 578.423 316.3 579.913 314.709C581.403 313.117 583.062 311.322 584.754 309.357C587.937 306.133 591.33 303.123 594.912 300.348C596.565 299.093 598.307 297.961 600.126 296.961C599.117 298.796 597.986 300.562 596.74 302.245C594.067 305.83 591.148 309.225 588.005 312.405C586.244 314.133 584.619 315.792 582.791 317.384C580.963 318.976 579.405 320.297 577.915 321.415C576.236 322.717 574.471 323.905 572.634 324.971V324.971Z" fill="#E9A727"/>
<path d="M760.54 165.173C762.771 164.699 765.035 164.393 767.312 164.259C769.309 164.259 771.679 163.988 774.083 163.988C776.487 163.988 779.263 163.988 781.938 164.225C786.845 164.566 791.721 165.256 796.53 166.291C798.829 166.747 801.093 167.37 803.302 168.154C801.067 168.605 798.805 168.91 796.53 169.068C791.616 169.408 786.683 169.408 781.769 169.068C779.094 169.068 776.453 168.594 773.948 168.222C771.442 167.849 769.14 167.477 767.176 167.002C764.925 166.545 762.708 165.933 760.54 165.173V165.173Z" fill="#E9A727"/>
<path d="M776.927 145.664C778.019 147.684 778.97 149.778 779.771 151.93C780.636 154.07 781.393 156.252 782.039 158.467C782.784 160.906 783.495 163.48 784.105 166.122C785.23 170.947 786 175.849 786.407 180.787C786.565 183.043 786.565 185.306 786.407 187.561C785.318 185.551 784.368 183.469 783.563 181.329C781.792 176.705 780.343 171.963 779.229 167.138C778.654 164.496 778.146 161.854 777.773 159.348C777.401 156.841 777.096 154.504 776.927 152.574C776.765 150.274 776.765 147.965 776.927 145.664V145.664Z" fill="#E9A727"/>
<path d="M853.613 233.726C857.163 235.301 860.562 237.194 863.77 239.382C870.279 243.659 876.346 248.571 881.884 254.048C884.71 256.764 887.293 259.721 889.603 262.888C886.06 261.3 882.662 259.408 879.446 257.232C876.207 255.15 873.087 252.889 870.102 250.458C867.071 248.059 864.177 245.492 861.434 242.769C858.571 239.987 855.954 236.961 853.613 233.726V233.726Z" fill="#F7EC41"/>
<path d="M880.293 226.681C879.818 230.594 879.002 234.459 877.855 238.231C876.772 241.99 875.485 245.648 874.063 249.238C872.625 252.844 870.997 256.371 869.188 259.806C867.415 263.37 865.33 266.77 862.958 269.967C863.437 266.054 864.253 262.19 865.396 258.417C866.445 254.691 867.766 251.033 869.188 247.409C870.632 243.806 872.26 240.279 874.063 236.842C875.836 233.278 877.921 229.877 880.293 226.681V226.681Z" fill="#F7EC41"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M265.607 455.969L321.155 647.38L347.281 791.094H373.504L347.328 455.969H265.607Z" fill="#DAB596"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M240.644 455.969C244.52 555.406 242.991 609.642 242.124 618.676C241.256 627.71 236.332 688.216 202.773 794.127H230.048C274.49 692.523 290.005 632.018 295.102 618.676C300.199 605.334 315.253 551.099 337.232 455.969H240.644Z" fill="#DAB596"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M264.415 455.969C278.864 521.834 303.854 626.971 339.385 771.381H377.739C380.572 623.158 368.521 525.603 347.652 455.969H264.415Z" fill="#69A1AC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M240.45 455.969C244.246 555.406 234.409 653.37 203.936 772.929H244.858C289.382 672.842 321.294 575.361 343.104 455.969H240.45Z" fill="#89C5CC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M198.886 813.84L200.391 789.578C209.202 792.155 220.238 791.144 233.499 786.545C247.757 796.645 265.707 803.542 287.349 807.235L287.349 807.235C289 807.517 290.11 809.084 289.828 810.735C289.81 810.842 289.786 810.948 289.756 811.052L287.676 818.389H233.499H201.896L198.886 813.84Z" fill="#191847"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M341.428 813.84L342.932 789.578C351.743 792.155 362.78 791.144 376.04 786.545C390.299 796.645 408.249 803.542 429.89 807.235L429.89 807.235C431.541 807.517 432.651 809.084 432.37 810.735C432.351 810.842 432.327 810.948 432.298 811.052L430.217 818.389H376.04H344.437L341.428 813.84Z" fill="#191847"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M298.018 254.479C291.031 246.277 286.301 236.225 287.092 224.962C289.369 192.511 334.543 199.65 343.33 216.029C352.117 232.407 351.073 273.954 339.716 276.881C335.187 278.049 325.539 275.189 315.719 269.27L321.883 312.875H285.489L298.018 254.479Z" fill="#DAB596"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M327.279 218.404C322.34 221.167 318.826 226.343 314.595 233.133C313.564 232.728 312.442 232.506 311.268 232.506C306.243 232.506 302.17 236.579 302.17 241.604C302.17 244.155 303.219 246.461 304.911 248.113C302.805 251.085 300.446 254.189 297.742 257.392C287.653 251.339 280.149 233.697 289.782 216.666C298.071 182.552 345.036 203.726 354.071 197.973C356.047 210.352 349.676 220.334 327.279 218.404Z" fill="#191847"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M246.555 455.239C294.228 460.294 331.752 460.294 359.127 455.239C362.519 454.613 363.054 448.935 361.481 445.081C336.988 385.067 320.192 332.701 320.192 291.017C315.698 293.283 307.833 293.622 296.597 292.036C266.399 334.993 252.519 384.281 246.555 455.239Z" fill="#323337"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M292.09 283.951H304.674C307.604 301.185 316.846 534.555 345.378 610.896H191C194.347 540.962 220.748 456.953 247.493 386.654C250.317 379.231 251.74 372.896 255.74 362.396L267.24 331.396C277.24 303.896 286.132 292.396 289.355 284.95C289.5 284.616 289.644 284.282 289.788 283.951H290.902C291.312 283.688 291.722 283.426 292.134 283.164L292.09 283.951Z" fill="#DDE3E9"/>
<path transform={rls} fill-rule="evenodd" clip-rule="evenodd" d="M192.46 408.68L219.318 412.581C190.488 475.132 175.032 508.212 172.95 511.822C168.265 519.945 173.775 534.093 175.929 541.583C165.044 544.726 168.52 527.908 154.756 532.103C142.193 535.932 131.826 544.612 119.384 534.29C117.854 533.022 116.565 528.872 121.427 526.455C133.541 520.431 151.326 509.41 154.091 505.348C157.861 499.808 170.651 467.586 192.46 408.68Z" fill="#DAB596"/>
<path transform={rls} fill-rule="evenodd" clip-rule="evenodd" d="M290.349 290.451H302.934C305.864 307.685 300.155 311.706 287.615 344.68L265 378.68C254.5 387.18 251.188 388.18 245.752 393.154C222.104 414.795 201.492 451.464 183.917 503.161L155 495.738C176.5 403.091 220.705 334.995 287.615 291.45C287.76 291.116 287.904 290.782 288.048 290.451H289.161C289.571 290.188 289.982 289.926 290.393 289.664L290.349 290.451Z" fill="#DDE3E9"/>
</svg>

  );
};

export default ErrorIllustration;
